(function (window, document, $, undefined) {
  'use strict';

  var app = (function () {
    var $private = {};
    var $public = {};
    var screen_width = $(window).width();

    // LGPD
    $public.lgpd = function () {
      var $wrapper = $('[data-cookies="modal"]');
      var $btn = $('[data-cookies="accept"]');
      // var tagmanagercode =
      //   "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WDW54K6');";

      $btn.on('click', function (e) {
        window.localStorage.setItem('acceptAll', true);
        addCode();
      });

      var addCode = function () {
        if (!!window.localStorage.getItem('acceptAll')) {
          $wrapper.remove();
          // $('head').append(
          //   "<script id='tagmanager'>" + tagmanagercode + '</script>'
          // );
        }
      };

      addCode();
    };

    $public.responsiveMenu = function () {
      $('#dd-main-menu').slicknav({
        prependTo: '.dd-responsive-nav',
        label: '',
      });
    };

    $public.share = function () {
      $('.dd-share').on('click', function (e) {
        e.preventDefault();
        var t = 640,
          n = 300,
          r = screen.width / 2 - t / 2,
          i = screen.height / 2 - n / 2;
        window.open(
          $(this).attr('href'),
          'popup',
          'width=' + t + ',height=' + n + ',top=' + i + ',left=' + r
        );
      });
    };

    $public.subMenu = function () {
      if (screen_width > 768) {
        $('.dd-has-sub').hover(function () {
          $(this).find('.dd-sub').stop(true, true).fadeToggle('fast');
        });
      }
    };

    $public.selectOpenLink = function () {
      $('.dd-magic-select').on('change', function () {
        var value = $(this).val();
        window.open(value, '_self');
      });
    };

    $public.maskInput = function () {
      $('.dd-tel').mask('(99) 9999-9999?9', { placeholder: ' ' });
    };

    return $public;
  })();

  // Global
  window.app = app;
  app.responsiveMenu();
  app.subMenu();
  app.selectOpenLink();
  app.maskInput();
  app.share();
  app.lgpd();
})(window, document, jQuery);

$(window).load(function () {});
